import React from 'react';
import { Box } from '@chakra-ui/react';
import Container from '../components/Container';

function LegalNotices() {
  return (
    <Container>
      <Box fontSize="large" w="100%">
        <Box fontSize="4xl">MENTIONS LEGALES</Box>
        <br />
        <p>
          Conformément aux dispositions de l'article 6-III et 19 de la Loi n°
          2004-575 du 21 juin 2004 relative à la confiance dans l'économie
          numérique, dite LCEN, il est précisé aux utilisateurs du site{' '}
          <a href="https://caniconnect.fr" target="_blank">
            caniconnect.fr
          </a>{' '}
          les informations qui suivent :
        </p>
        <br />
        <p>
          Le site{' '}
          <a href="https://caniconnect.fr" target="_blank">
            caniconnect.fr
          </a>{' '}
          est l'unique propriété de la société <strong>Cani Connect</strong> qui
          l'édite.
        </p>
        <br />
        <p>
          Numéro de téléphone : <strong>0656666906</strong>
        </p>
        <p>
          Adresse : <strong>13 rue de Malieu</strong>{' '}
          <strong>88600 Domfaing</strong>
          <br />
          Inscrite au Registre du Commerce et des Sociétés (RCS) sous le numéro{' '}
          <strong>91295266000013</strong>
        </p>
        <p>
          Numéro de TVA : <strong>FR39912952660</strong>
          <br />
          Adresse mail :{' '}
          <strong>
            <a href="mailto:contact@caniconnect.fr?subject=Contact a partir des mentions légales via le site caniconnect.fr">
              contact@caniconnect.fr
            </a>
          </strong>
          <br />
          <br />
          Le site <strong>Cani Connect</strong> est hébergé par Hostinger, dont
          le siège social est situé au 61 Lordou Vironos Street, 6023 Larnaca,
          Chypre.
        </p>
      </Box>
    </Container>
  );
}

export default LegalNotices;
